define("pix-admin/components/pix-return-to", ["exports", "pix-ui/components/pix-return-to"], function (_exports, _pixReturnTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _pixReturnTo.default;
    }
  });
});
